import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AffiliateDashboardData, API } from "../api";
import { ERROR_CODES, isErrorCode } from "../error";
import { hydrateTokenStore, tokenStore } from "../hooks/useToken";
import { getRewardfulReferralData } from "../lib/rewardful";
import { FullPublicUser } from "../types";
import { errorProxyWrapper } from "../util";

interface UserState {
  user: FullPublicUser | null;
  retrieved: boolean;
  initialized: boolean;
  loginSubmitError: boolean;
}

interface AffiliateState {
  data: AffiliateDashboardData | null;
  setCodeError: null | string;
  loading: boolean;
}

interface UserContext {
  userState: UserState;
  refreshUser: () => Promise<void>;
  loginUserUsingEmailToken: (email: string) => Promise<void>;
  updatePaypalEmail: (email: string) => Promise<void>;
  createCustomCode: (code: string) => Promise<void>;
  createDefaultCode: () => Promise<void>;
  loadAffiliateStats: (loadIfCached: boolean) => Promise<void>;
  affiliateState: AffiliateState;
}

const uninitializedContext = new Error(
  "UserContext has not been initalized. Is this component a child of <UserProvider />?",
);
const UserContext = React.createContext<UserContext>(
  errorProxyWrapper({} as UserContext, uninitializedContext),
);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userState, setUserState] = useState<UserState>({
    user: null,
    retrieved: false,
    loginSubmitError: false,
    initialized: false,
  });
  const [affiliateState, setAffiliateState] = useState<AffiliateState>({
    data: null,
    loading: false,
    setCodeError: null,
  });

  const affiliateRetrieved = useRef(false);
  const loadAffiliateStats = useCallback(async (loadIfCached: boolean) => {
    if (!tokenStore.token) {
      return;
    }

    if (!loadIfCached && affiliateRetrieved.current) {
      return;
    }

    try {
      setAffiliateState((s) => ({ ...s, loading: true }));
      const data = await API.affiliateStats();
      if (!data) {
        console.error("Stats not present on /api/affiliate/dashboard data object", data);
      }
      affiliateRetrieved.current = true;
      setAffiliateState((s) => ({ ...s, data, loading: false }));
    } catch (e) {
      affiliateRetrieved.current = true;
      setAffiliateState((s) => ({ ...s, data: null, loading: false }));
    }
  }, []);

  const refreshUser = useCallback(async () => {
    if (!tokenStore.token) {
      setUserState((s) => ({ ...s, initialized: true, user: null }));
      return;
    }

    try {
      const res = await API.me();
      const user = res?.data?.user;
      if (!user) {
        console.error("User not present on /api/user/me response object", res?.data);
      }
      setUserState((prev) => ({ ...prev, retrieved: true, initialized: true, user }));
    } catch (e) {
      setUserState((prev) => ({ ...prev, retrieved: true, initialized: true, user: null }));
    }
  }, []);

  useEffect(() => {
    if (userState?.user?.id) {
      localStorage.setItem("user_id", userState.user.id);
    } else {
      localStorage.removeItem("user_id");
    }
  }, [userState]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  const loginUserUsingEmailToken = useCallback(
    async (emailToken: string) => {
      if (!emailToken) {
        return;
      }

      if (!tokenStore.securityToken) {
        hydrateTokenStore();
        if (!tokenStore.securityToken) {
          console.warn("No security token even after hydration", tokenStore.securityToken);
        }
      }
      try {
        const loginSession = await API.submitLogin(
          emailToken,
          tokenStore.securityToken,
          getRewardfulReferralData(),
        );
        const session = loginSession.data?.session;
        if (!session) {
          console.error("No session in the response", { loginSession });
          return;
        }

        const sessionToken = loginSession.data.session;
        tokenStore.token = sessionToken;
        refreshUser();
      } catch (e) {
        setUserState((prev) => ({ ...prev, loginSubmitError: true }));
        if (axios.isAxiosError(e)) {
          console.error("Got an HTTP error while submitting login", e, {
            json: e?.toJSON(),
            request: e?.request,
            data: e?.response?.data,
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
          datadogRum.addError(e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
            json: e?.toJSON(),
            request: e?.request,
            data: e?.response?.data,
          });
        } else {
          console.error("Unknown error fom submitting login", e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
          datadogRum.addError(e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
        }
      }
    },
    [refreshUser],
  );

  const updatePaypalEmail = useCallback(
    async (email: string) => {
      setAffiliateState((s) => ({ ...s, loading: true }));
      await API.updatePaypalEmail(email);
      await loadAffiliateStats(true);
    },
    [loadAffiliateStats],
  );

  const createCustomCode = useCallback(
    async (code: string) => {
      try {
        setAffiliateState((s) => ({ ...s, loading: true }));
        await API.createCustomCode(code);
        await loadAffiliateStats(true);
      } catch (e) {
        if (isErrorCode(e, ERROR_CODES.BAD_FORM_VALUES)) {
          setAffiliateState((s) => ({ ...s, setCodeError: "invalid code format", loading: false }));
          return;
        }

        if (isErrorCode(e, ERROR_CODES.UNIQUE_CODE_CLASH)) {
          setAffiliateState((s) => ({
            ...s,
            setCodeError: "Someone else is using that code, pick another one!",
            loading: false,
          }));
          return;
        }
        setAffiliateState((s) => ({ ...s, setCodeError: "unknown error", loading: false }));
      }
    },
    [loadAffiliateStats],
  );

  const createDefaultCode = useCallback(async () => {
    await API.createDefaultCode();
    await loadAffiliateStats(true);
  }, [loadAffiliateStats]);

  const userContextValue = useMemo<UserContext>(
    () => ({
      userState,
      refreshUser,
      loadAffiliateStats,
      affiliateState,
      createCustomCode,
      loginUserUsingEmailToken,
      updatePaypalEmail,
      createDefaultCode,
    }),
    [
      loginUserUsingEmailToken,
      loadAffiliateStats,
      affiliateState,
      refreshUser,
      userState,
      updatePaypalEmail,
      createCustomCode,
      createDefaultCode,
    ],
  );

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

export const useUser = () => React.useContext(UserContext);
